import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>E-commerce, Order and Local Delivery System for your company</h1>
    <h2>Interested?</h2>
    <p>
      Deliver Me Goodies is a software platform you own end-to-end. 
      Deliver Me Goodies allows your home-based or commercial business 
      to manage users, take credit card orders and limit your deliveries
      to a local range within minutes from your home or business. 
    </p>
    <p>
      We are looking for pilot users to help improve our software, if you 
      are interested, please let us know. {' '}
      <a href="https://www.mercuryrisingbakehouse.com/inquiry">Contact Us!</a>
    </p>
  </Layout>
)

export default IndexPage
